
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"

const ENTITYID="apijwtuserjsonmenutype"


const apijwtuserproviderpickerfields = [
	{"label":"Name", "dbfield": "apijwtuserprovider_name", "type": "text", "filtertype": "textbox"},
];

const apijwtuserproviderpickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Name", "dbfield": "apijwtuserjsonmenutype_name", "type": "text", "filtertype": "text"},
	{"label":"Code/Prefix", "dbfield": "apijwtuserjsonmenutype_prefix", "type": "text", "filtertype": "text"},
	{"label":"Description", "dbfield": "apijwtuserjsonmenutype_description", "type": "text", "filtertype": "text"},
];

const formFields = [
	[
		{"label":"Service", "field": "apijwtuserprovider_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Code/Prefix", "field": "apijwtuserjsonmenutype_prefix", "value": "", "input": "text", "mode": "readonly"},

	]
];


const newFormFields = [
	[
		{"label":"Service", "field": "apijwtuserprovider_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": apijwtuserproviderpickerfields, "pickerfilters": apijwtuserproviderpickerfilter},
		{"label":"Code/Prefix", "field": "apijwtuserjsonmenutype_prefix", "value": "", "input": "text", "mode": "unique", "info":"Unique code to identify service; parent folder name"},
	]
];


//swebappsiamnet/12/apijwtuserjsonmenu_data/1

const commonFields = [
	{"label":"Name", "field": "apijwtuserjsonmenutype_name", "value": "", "input": "text", "mode": "required"},
	{"label":"Description", "field": "apijwtuserjsonmenutype_description", "value": "", "input": "textarea", "mode": "required"},
];

const ApijwtmenusetForm = ({location, allowadd, allowdelete}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");


	return <Layout location={location}
				newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
				newtokenHandler={(newtoken)=>{setToken(newtoken)}}
				privatePage={true} usegatedcontent={true}>
			<WebappsForm
				searchmessage={<div className="text-centered">Master List of Entities/Websites that will have menus.</div>}
				location={location}
				allowadd={allowadd}
				allowdelete={allowdelete}
				pagetitle={pagetitle}
				mobilerowtitlefield={["apijwtuserjsonmenutype_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(commonFields)]}
				newformFields={[newFormFields[0].concat(commonFields)]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default ApijwtmenusetForm;
