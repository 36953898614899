
import React from "react"

import ApijwtmenusetForm from "../../components/webapps/forms/apijwtmenuset";

const UsermenusetPage = ({location}) => {

	return <ApijwtmenusetForm
				location={location}
				allowadd={true}
				allowdelete={false}
			/>
}

export default UsermenusetPage;
